import { Box, Text, VStack, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React from "react";
import Slider from "react-slick";
import type { IHomePageSectionProps, ISectionReviewCardProps } from "../interfaces";
import { getPaths } from "lib/utils/paths";
import type { ReviewTranslations} from "src/contexts/LocalesContext";
import { useLocales } from "src/contexts/LocalesContext";
import LayoutContainer from "components/layout/LayoutContainer";

const ReviewCard = ({ borderBottom, review, loading }: ISectionReviewCardProps) => {
  const { getTranslations } = useLocales();
  const translatedContent = getTranslations<ReviewTranslations>(review?.review_translations as ReviewTranslations[]);

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection={["column", "column", "row", "row"]}
      mb="8"
    >
      <Box
        bgImage={review?.image}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPos="center"
        h="160px"
        w="160px"
        role="group"
        rounded="100%"
        mr="12"
        mb="12"
      />
      <Box
        flex={1}
        borderColor="textGray"
        borderBottomWidth={borderBottom ? 0.5 : 0}
      >
        <Skeleton isLoaded={!loading}>
          <Text fontSize="lg" mb="6" lineHeight="1.8">
            {translatedContent?.text ?? ""}
          </Text>
        </Skeleton>
        <Skeleton isLoaded={!loading}>
          <Text fontSize="3xl" fontWeight="bold" mb="10">
            {review?.name}
          </Text>
        </Skeleton>
      </Box>
    </Box>
  );
};


const ReviewsSection = ({ data, loading }: IHomePageSectionProps) => {
  const t = useTranslations("pages.home.reviewsSection");
  const [isDesktop] = useMediaQuery("(min-width: 768px)");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    rows: isDesktop ? 2 : 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    appendDots: (dots: any) => (
      <Box
        pl={isDesktop ? "190px" : "0px"}
        display={isDesktop ? "block" : "flex"}
        justifyContent="center"
        alignItems="center"
      >
        <ul> {dots} </ul>
      </Box>
    ),
    // eslint-disable-next-line i18next/no-literal-string
    style: { width: "100%", height: "100%" },
  };

  return (
    <>
      <Box
        id={getPaths.index.sections.reviews.id()}
        className="anchor"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="textGray"
        py="16"
      >
        <LayoutContainer>
          <Text
            fontSize={["4xl", "4xl", "5xl", "5xl"]}
            fontWeight="bold"
            color="white"
            pb="8"
          >
            {t("title")}
          </Text>
          <Text color="white" fontSize="xl" lineHeight="10">
            {t("subtitle1")}
          </Text>
          <Text color="white" fontSize="xl" lineHeight="10" maxW="680px">
            {t("subtitle2")}
          </Text>
        </LayoutContainer>
      </Box>
      <LayoutContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
        my="6"
      >
        {loading ? (
          <VStack
            w="full"
            spacing={8}
            px={1}
            pb={5}
          >
            {[1, 2].map(i => <ReviewCard key={i} borderBottom={false} />)}
          </VStack>
        ) : data?.review.length ? (
          <Slider {...settings}>
            {data?.review.map((review, i) => (
              <ReviewCard
                key={review.id}
                borderBottom={!isDesktop || i % 2 === 0}
                review={review}
              />
            ))}
          </Slider>
        ) : (
          <Box />
        )}
      </LayoutContainer>
    </>
  );
};

export default ReviewsSection;
