import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslations } from "next-intl";
import LayoutContainer from "components/layout/LayoutContainer";
import Link from "next/link";
import { getPaths } from "lib/utils/paths";

const CallToActionSection = ({
  name,
}: {
  name: keyof typeof staticDataText;
}) => {
  const t = useTranslations("pages.home.callToActionSection");

  /* eslint-disable i18next/no-literal-string */
  const staticDataText = {
    athletes: {
      bgColor: "brandOrange.500",
      subtitle: t("athletes.callToActionEntity"),
      id: getPaths.index.sections.sports.id(),
      link: getPaths.register.athlete.step(1),
      items: [
        t("athletes.callToActionSubtitle1"),
        t("athletes.callToActionSubtitle2"),
        t("athletes.callToActionSubtitle3"),
      ],
    },
    patients: {
      bgColor: "brandOrange.500",
      subtitle: t("patients.callToActionEntity"),
      id: "",
      link: getPaths.register.athlete.step(1),
      items: [
        t("patients.callToActionSubtitle1"),
        t("patients.callToActionSubtitle2"),
        t("patients.callToActionSubtitle3"),
      ],
    },
    coaches: {
      bgColor: "homeSectionBlue",
      subtitle: t("coaches.callToActionEntity"),
      id: getPaths.index.sections.coaches.id(),
      link: getPaths.register.coach.step(1),
      items: [
        t("coaches.callToActionSubtitle1"),
        t("coaches.callToActionSubtitle2"),
        t("coaches.callToActionSubtitle3"),
      ],
    },
    healthProfessionals: {
      bgColor: "homeSectionBlue",
      subtitle: t("healthProfessionals.callToActionEntity"),
      id: getPaths.index.sections.healthProfessionals.id(),
      link: getPaths.register.coach.step(1),
      items: [
        t("healthProfessionals.callToActionSubtitle1"),
        t("healthProfessionals.callToActionSubtitle2"),
        t("healthProfessionals.callToActionSubtitle3"),
      ],
    },
  };
  /* eslint-enable i18next/no-literal-string */

  return (
    <Box
      id={staticDataText[name].id}
      className="anchor"
      bg={staticDataText[name].bgColor}
      py={6}
      alignItems="center"
      justifyContent="center"
    >
      <LayoutContainer>
        <Text
          fontSize={["4xl", "5xl", "6xl", "6xl"]}
          fontWeight="bold"
          color="black"
          textTransform="uppercase"
        >
          {t("register")}
        </Text>
        <Text
          fontSize={["3xl", "4xl", "5xl", "5xl"]}
          fontWeight="bold"
          color="white"
          textTransform="uppercase"
          mb={8}
        >
          {`> ${staticDataText[name].subtitle}`}
        </Text>
        <Box
          display="flex"
          flexDirection={["column", "column", "row", "row"]}
          justifyContent={["stretch", "stretch", "center", "center"]}
          alignItems={["flex-start", "flex-start", "center", "center"]}
        >
          <Box
            w="100%"
            flex={["", "", 2, 2]}
          >
            {staticDataText[name].items.map((item, index) => (
              <Text
                key={index}
                mt={2}
                fontSize={["sm", "md", "lg", "lg"]}
                color="black"
              >
                {`> ${item}`}
              </Text>
            ))}
          </Box>
          <Box
            w="100%"
            flex={["", "", 1, 1]}
            mt={[12, 12, 0, 0]}
          >
            <Link href={staticDataText[name].link}>
              <Button
                bg="transparent"
                color="white"
                _hover={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                size="lg"
                w="100%"
                py={8}
                borderColor="white"
                borderWidth={1}
                fontSize={["xl", "xl", "2xl", "2xl"]}
              >
                {t("registerNow")}
              </Button>
            </Link>
          </Box>
        </Box>
      </LayoutContainer>
    </Box>
  );
};

export default CallToActionSection;
