import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslations } from "next-intl";
import useTranslationConstants from "lib/utils/constants";
import LayoutContainer from "components/layout/LayoutContainer";

const DetailsSection = () => {
  const t = useTranslations("pages.home.DetailsSection");
  const { LINKS } = useTranslationConstants();

  return (
    <>
      <Box
        h="100vw"
        maxH={["350px", "350px", "560px", "560px"]}
        bgImage={LINKS.images.forAthletes}
        bgSize="cover"
        bgPos={["center center", "center center", "center bottom", "center bottom"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontSize={["2xl", "2xl", "3xl", "3xl"]}
          fontWeight="bold"
          color="white"
          textAlign="center"
        >
          {t("forAthletes.title")}
        </Text>
      </Box>
      <HStack justifyContent="center">
        <LayoutContainer my="4" mx={[4, 4, 0, 0]}>
          {[1, 2, 3, 4].map(i => (
            <Text
              key={i}
              as="span"
              display="inline-block"
              mb="6"
            >
              <Text
                color="brandOrange.500"
                display="inline"
                mr="2"
              >
                {"> "}
              </Text>
              {t(`forAthletes.text${i}`)}
            </Text>
          ))}
        </LayoutContainer>
      </HStack>
      <Box
        h="100vw"
        maxH={["350px", "350px", "560px", "560px"]}
        bgImage={LINKS.images.forHealthProfessionals}
        bgSize="cover"
        bgPos={["right center", "right center", "center top", "center top"]}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          fontSize={["2xl", "2xl", "3xl", "3xl"]}
          fontWeight="bold"
          color="white"
          textAlign="center"
        >
          {t("forHealthProfessionals.title")}
        </Text>
      </Box>
      <HStack justifyContent="center">
        <LayoutContainer my="4" mx={[4, 4, 0, 0]}>
          {[1, 2, 3, 4, 5].map(i => (
            <Text
              key={i}
              as="span"
              display="inline-block"
              mb="6"
            >
              <Text
                color="brandBlue.500"
                display="inline"
                mr="2"
              >
                {"> "}
              </Text>
              {t(`forHealthProfessionals.text${i}`)}
            </Text>
          ))}
        </LayoutContainer>
      </HStack>
    </>
  );
};

export default DetailsSection;
