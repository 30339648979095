import { SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import React from "react";
import type { IHomePageSectionProps } from "../interfaces";
import { getPaths } from "lib/utils/paths";
import { useLocales } from "src/contexts/LocalesContext";
import LayoutContainer from "components/layout/LayoutContainer";
import HomepageCard from "./HomepageCard";

// lang types
type TempLangType = "pt" | "en" | "es";

const tempTranslations = {
  /* eslint-disable i18next/no-literal-string */
  remo: {
    pt: "Remo - brevemente",
    en: "Rowing - coming soon",
    es: "Remo - próximamente",
  },
  canoagem: {
    pt: "Canoagem - brevemente",
    en: "Canoeing - coming soon",
    es: "Canoa - próximamente",
  },
  /* eslint-enable i18next/no-literal-string */
};

const SportsSection = ({ data, loading }: IHomePageSectionProps) => {
  const t = useTranslations("pages.home.sportsSection");
  const { getTranslations, currentLocale } = useLocales();

  // eslint-disable-next-line i18next/no-literal-string
  const currentCode = (currentLocale?.code || "pt") as TempLangType;

  return (
    <LayoutContainer>
      <Text
        fontSize="3xl"
        fontWeight="bold"
        color="black"
        py="6"
      >
        {t("sports")}
      </Text>
      <SimpleGrid
        w="full"
        mb={20}
        gap={8}
        columns={[1, 1, 3, 3]}
      >
        {loading
          ? [1, 2, 3].map(i => <HomepageCard key={i} loading />)
          : (
            <>
              {data?.sport.map(sport => {
                const sportTranslations = sport && getTranslations(sport.sport_translations);

                return (
                  <HomepageCard
                    key={sport.id}
                    image={sport.image}
                    lowerLeftComponent={sportTranslations?.title}
                    link={getPaths.sport.id(sport.id)}
                  />
                );
              })}
              <HomepageCard
                key="canoagem"
                blackedOut
                // eslint-disable-next-line i18next/no-literal-string
                image="https://ik.imagekit.io/leaplane/avatares/CANOAGEM-min_m22_8OmBW.jpg"
                // eslint-disable-next-line i18next/no-literal-string
                lowerLeftComponent={tempTranslations.canoagem[currentCode]}
                />
              <HomepageCard
                key="remo"
                blackedOut
                // eslint-disable-next-line i18next/no-literal-string
                image="https://ik.imagekit.io/leaplane/avatares/REMO-min_ei-I5J8FU.jpg"
                // eslint-disable-next-line i18next/no-literal-string
                lowerLeftComponent={tempTranslations.remo[currentCode]}
              />
            </>
          )}
      </SimpleGrid>
    </LayoutContainer>
  );
};

export default SportsSection;
