import type { NextPage } from "next";
import { getStaticProps as gsp } from "locales/utils";
import HomePage from "../src/pages/Home/HomePage";

const Home: NextPage = () => {
  return <HomePage />;
};

export default Home;

export const getStaticProps = gsp;
