import {
  Box,
  Icon,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  VStack,
  HStack,
  Avatar,
  Text,
  Input,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import { formatDate } from "lib/utils/dateFormater";
import useTranslationConstants from "lib/utils/constants";
import { useRouter } from "next/router";

const WhatsappPopover = () => {
  const { locale } = useRouter();
  const t = useTranslations("pages.home.whatsappPopover");
  const { LINKS, whatsAppUrl } = useTranslationConstants();
  const [message, setMessage] = useState<string>(t("whatsappMessage"));
  const phoneNumber = process.env.NEXT_PUBLIC_WHATSAPP_NUMBER;

  const placement: any = useBreakpointValue({ base: "top", md: "left-start" });
  const [whatsappUrl, setWhatsappUrl] = useState("");

  useEffect(() => {
    setMessage(t("whatsappMessage"));
  }, [locale]);

  useEffect(() => {
    if (phoneNumber) {
      setWhatsappUrl(whatsAppUrl(phoneNumber, t("whatsappMessage")));
    }
  }, [phoneNumber]);

  return (
    <Box id="whatsapp" pos="fixed" bottom={10} right={10} zIndex="docked">
      <Popover preventOverflow flip placement={placement}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <IconButton
                shadow="lg"
                aria-label="Whatsapp"
                size="lg"
                rounded="full"
                colorScheme="whatsapp"
                icon={<Icon fontSize="2xl" as={FaWhatsapp} />}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton color="white" />
              <PopoverHeader bg="#075E54">
                <HStack w="full" px={1.5} py={1} spacing={3}>
                  <Avatar src={LINKS.images.miniLogoPng} />
                  <VStack align={"flex-start"} spacing={1}>
                    <Text color="white" fontWeight={"bold"}>
                      {t("whatsappName")}
                    </Text>
                    <Text color="white" fontSize="xs" lineHeight="shorter">
                      {t("subtitle")}
                    </Text>
                  </VStack>
                </HStack>
              </PopoverHeader>
              <PopoverBody p={0} roundedBottom={"md"}>
                <VStack w="full" roundedBottom={"md"} spacing={0}>
                  <VStack
                    px={3}
                    py={5}
                    w="full"
                    align={"flex-start"}
                    backgroundImage={`url(${LINKS.images.backgroundWhatsapp})`}
                    backgroundSize={"cover"}
                  >
                    <VStack
                      bg="white"
                      p={2}
                      shadow={"base"}
                      align={"flex-start"}
                      w="80%"
                      rounded="md"
                    >
                      <Text fontSize={"sm"} fontWeight="bold">
                        {t("whatsappName")}
                      </Text>
                      <Text
                        fontSize={"sm"}
                        lineHeight={"shorter"}
                        color="gray.800"
                      >
                        {t("messageReceived")}
                      </Text>
                      <HStack justifyContent={"flex-end"} w="full" spacing={1}>
                        <Text fontSize={"xs"} lineHeight={"shorter"}>
                          {formatDate(new Date(), "HH:mm")}
                        </Text>
                        <Icon as={BiCheckDouble} />
                      </HStack>
                    </VStack>
                  </VStack>
                  <HStack w="full" bg="gray.100" p={1.5} roundedBottom={"md"}>
                    <Input
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      placeholder={t("messagePlaceholder")}
                      bg="white"
                      rounded={"full"}
                    />
                    <a
                      href={!message ? undefined : whatsappUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconButton
                        onClick={() => {
                          setTimeout(() => {
                            setMessage("");
                            onClose();
                          }, 500);
                        }}
                        colorScheme={"gray"}
                        aria-label={t("messagePlaceholder")}
                        icon={<Icon as={IoSendSharp} fontSize={"xl"} />}
                        disabled={!message}
                      />
                    </a>
                  </HStack>
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default WhatsappPopover;
