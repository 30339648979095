import {
  Box,
  Heading,
  VStack,
  LinkBox,
  Skeleton,
} from "@chakra-ui/react";
import Link from "next/link";

const HomepageCard = ({
  image,
  link,
  lowerLeftComponent,
  topRightComponent,
  loading,
  blackedOut = false,
}: {
  image?: string;
  link?: string;
  lowerLeftComponent?: React.ReactNode | string;
  topRightComponent?: React.ReactNode | string;
  loading?: boolean;
  blackedOut?: boolean;
}) => {

  const WithLink = ({ children }: { children: React.ReactNode }) => (!link
    ? children
    : <Link passHref href={link}>{children}</Link>);

  return (
    <Skeleton isLoaded={!loading}>
      <LinkBox
        pos="relative"
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        bgImg={image}
        h="280px"
        w="full"
      >
        <Box
          pos="absolute"
          inset={0}
          zIndex={5}
          bg={blackedOut ? "rgba(0,0,0,0.55)" : "rgba(0,0,0,0.2)"}
        />
        <WithLink>
          <Box w="full" h="full">
            <VStack
              w="full"
              h="full"
              justify="flex-end"
              align="flex-start"
              zIndex={10}
              pos="relative"
              p={5}
            >
              {typeof lowerLeftComponent === "string" ? (
                <Heading size="sm" color="white">
                  {lowerLeftComponent}
                </Heading>
              ) : lowerLeftComponent}
            </VStack>
            <VStack
              w="full"
              h="full"
              justify="flex-start"
              align="flex-end"
              zIndex={10}
              pos="absolute"
              top="0px"
              cursor={link ? "pointer" : "default"}
              p={5}
            >
              {typeof topRightComponent === "string" ? (
                <Heading size="sm" color="white">
                  {topRightComponent}
                </Heading>
              ) : topRightComponent}
            </VStack>
          </Box>
        </WithLink>
      </LinkBox>
    </Skeleton>
  );
};

export default HomepageCard;
