import type { NextPage } from "next";
import PublicNavbarLayout from "components/layout/PublicNavbarLayout";
import { useHomePageQuery } from "lib/api/sdk";
import HeaderSection from "./sections/HeaderSection";
import DetailsSection from "./sections/DetailsSection";
import AboutUsSection from "./sections/AboutUsSection";
import MissionSection from "./sections/MissionSection";
import CallToActionSection from "./sections/CallToActionSection";
import ReviewsSection from "./sections/ReviewsSection";
import SportsSection from "./sections/SportsSection";
import WhatsappPopover from "./WhatsappPopover";
import HomeImageGallerySection from "./sections/HomeImageGallerySection";

const HomePage: NextPage = () => {
  const { data, loading } = useHomePageQuery();

  return (
    <PublicNavbarLayout>
      <HeaderSection />
      <DetailsSection />
      <AboutUsSection />
      <CallToActionSection name="athletes"/>
      <SportsSection data={data} loading={loading} />
      <CallToActionSection name="patients"/>
      <HomeImageGallerySection name="health" />
      <CallToActionSection name="coaches"/>
      <HomeImageGallerySection name="coaches" />
      <CallToActionSection name="healthProfessionals"/>
      <HomeImageGallerySection name="healthProfessionals" />
      <MissionSection />
      <ReviewsSection data={data} loading={loading} />
      <WhatsappPopover />
    </PublicNavbarLayout>
  );
};

export default HomePage;
