import React from "react";
import { SimpleGrid, Text } from "@chakra-ui/react";
import { useTranslations } from "next-intl";
import LayoutContainer from "components/layout/LayoutContainer";
import HomepageCard from "./HomepageCard";
import useTranslationConstants from "lib/utils/constants";

const HomeImageGallerySection = ({
  name,
}: {
  name: keyof typeof staticDataGalleries;
}) => {
  const t = useTranslations("pages.home.imageGallerySection");
  const { LINKS } = useTranslationConstants();

  const staticDataGalleries = {
    health: {
      title: t("health.title"),
      images: [
        {
          title: t("health.image1"),
          image: LINKS.images.gallerySection.health.image1,
        },
        {
          title: t("health.image2"),
          image: LINKS.images.gallerySection.health.image2,
        },
        {
          title: t("health.image3"),
          image: LINKS.images.gallerySection.health.image3,
        },
      ],
    },
    coaches: {
      title: "",
      images: [
        {
          title: t("coaches.image1"),
          image: LINKS.images.gallerySection.coaches.image1,
        },
        {
          title: t("coaches.image2"),
          image: LINKS.images.gallerySection.coaches.image2,
        },
        {
          title: t("coaches.image3"),
          image: LINKS.images.gallerySection.coaches.image3,
        },
      ],
    },
    healthProfessionals: {
      title: "",
      images: [
        {
          title: t("healthProfessionals.image1"),
          image: LINKS.images.gallerySection.healthProfessionals.image1,
        },
        {
          title: t("healthProfessionals.image2"),
          image: LINKS.images.gallerySection.healthProfessionals.image2,
        },
        {
          title: t("healthProfessionals.image3"),
          image: LINKS.images.gallerySection.healthProfessionals.image3,
        },
      ],
    },
  };

  const hasTitle = staticDataGalleries[name].title;

  return (
    <LayoutContainer>
      {hasTitle && (
        <Text
          fontSize="3xl"
          fontWeight="bold"
          color="black"
          py="6"
        >
          {staticDataGalleries[name].title}
        </Text>
      )}
      <SimpleGrid
        w="full"
        mt={hasTitle ? 0 : 2}
        mb={20}
        gap={8}
        columns={[1, 1, 3, 3]}
      >
        {staticDataGalleries[name].images.map(image => (
          <HomepageCard
            key={image.title}
            image={image.image}
            lowerLeftComponent={image.title}
          />
        ))}
      </SimpleGrid>
    </LayoutContainer>
  );
};

export default HomeImageGallerySection;
