import { Box, Text, Image, SimpleGrid, VStack, Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslations } from "next-intl";
import useTranslationConstants from "lib/utils/constants";
import LayoutContainer from "components/layout/LayoutContainer";

const AboutUsSection = () => {
  const t = useTranslations("pages.home.AboutUsSection");
  const { LINKS } = useTranslationConstants();

  const data = [
    {
      id: 1,
      image: LINKS.images.aboutUs.trainingPlans,
      title: t("trainingPlansTitle"),
      text: t("trainingPlans"),
    },
    {
      id: 2,
      image: LINKS.images.aboutUs.trainingPrescription,
      title: t("trainingPrescriptionTitle"),
      text: t("trainingPrescription"),
    },
    {
      id: 3,
      image: LINKS.images.aboutUs.comunication,
      title: t("comunicationTitle"),
      text: t("comunication"),
    },
    {
      id: 4,
      image: LINKS.images.aboutUs.automaticLogging,
      title: t("automaticLoggingTitle"),
      text: t("automaticLogging"),
    },
    {
      id: 5,
      image: LINKS.images.aboutUs.integration,
      title: t("integrationTitle"),
      text: t("integration"),
    },
    {
      id: 6,
      image: LINKS.images.aboutUs.strava,
      title: t("stravaTitle"),
      text: t("strava"),
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="textGray"
        py="16"
      >
        <LayoutContainer>
          <Stack
            direction={["column", "column", "column", "row"]}
            justify="space-between"
            align={["center", "center", "center", "stretch"]}
          >
            <VStack align="flex-start" w="full" spacing={0}>
              <Text
                fontSize={["4xl", "4xl", "5xl", "5xl"]}
                fontWeight="bold"
                color="white"
                pb="8"
              >
                {t("whatIsMyHeart")}
              </Text>
              <Text color="white" fontSize="xl" lineHeight="10">
                {t("digitalPlatform")}
              </Text>
            </VStack>
            <Image
              src={LINKS.images.missionLogoWhite}
              alt={t("whatIsMyHeart")}
              w="full"
              maxW="200px"
              mr={["0", "0", "0", "8%"]}
              mt={["8", "8", "8", "0"]}
            />
          </Stack>
        </LayoutContainer>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={16}
      >
        <LayoutContainer>
          <SimpleGrid
            columns={[1, 2, 3, 3]}
            w="full"
          >
            {data.map(item => (<AboutUsCard key={item.id} {...item} />))}
          </SimpleGrid>
        </LayoutContainer>
      </Box>
    </>
  );
};

const AboutUsCard = ({
  image,
  text,
  title
}: {
  image: string,
  text: string,
  title: string
}) => (
  <Box px="12">
    <Box px="8">
      <Image src={image} alt={text} />
    </Box>
    <Text
      fontSize={["xl", "xl", "lg", "lg"]}
      align="center"
      fontWeight="bold"
    >
      {title}
    </Text>
    <Text
      fontSize={["xl", "xl", "lg", "lg"]}
      align="center"
    >
      {text}
    </Text>
  </Box>
);

export default AboutUsSection;
