import { Heading, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslations } from "next-intl";
import useTranslationConstants from "lib/utils/constants";
import LayoutContainer from "components/layout/LayoutContainer";

const HeaderSection = ({ hideText = false }: { hideText?: boolean }) => {
  const t = useTranslations("pages.home.headerSection");
  const { LINKS } = useTranslationConstants();

  return (
    <LayoutContainer
      h={hideText ? "auto" : "100vh"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={hideText ? 32 : 0}
      mb={hideText ? 20 : 0}
      pt={[8, 8, 12, 24]}
      gap={[16, 16, 16, 24]}
    >
      <Image
        alt={t("logoAlt")}
        src={LINKS.images.bigLogo}
        h={["30%", "30%", "40%", "40%"]}
      />
      {!hideText && <Heading
        as="h1"
        mx={["8", "8", "24", "24"]}
        fontSize={["md", "md", "xl", "xl"]}
        textAlign="center"
        fontWeight={400}
        textTransform="uppercase"
        letterSpacing={2}
        lineHeight={2}
      >
        {t("subtitle")}
      </Heading>}
    </LayoutContainer>
  );
};

export default HeaderSection;
