import { Box, HStack, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslations } from "next-intl";
import useTranslationConstants from "lib/utils/constants";
import { getPaths } from "lib/utils/paths";
import LayoutContainer from "components/layout/LayoutContainer";

const MissionSection = () => {
  const t = useTranslations("pages.home.missionSection");
  const { LINKS } = useTranslationConstants();

  const MISSION_TEXT = [
    t("contentTitle1"),
    t("contentTitle2"),
    t("contentTitle3"),
    t("contentTitle4"),
    t("contentTitle5"),
  ];

  const ENDING_TEXT = [
    t("endingTitle1"),
    t("endingTitle2"),
    t("endingTitle3"),
    t("endingTitle4"),
  ];

  const FINAL_TEXT = t("finalTitle");

  return (
    <LayoutContainer
      id={getPaths.index.sections.mission.id()}
      className="anchor"
    >
      <Box
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
      >
        <HStack
          flex={1}
          alignItems="flex-start"
          justifyContent="flex-start"
          mr={[0, 0, 16, 16]}
        >
          <Image src={LINKS.images.missionLogo} alt="mission" />
        </HStack>

        <Box flex={2} mb={24}>
          <Text
            fontSize={["5xl", "6xl", "6xl", "6xl"]}
            fontWeight="bold"
            color="black"
            mb="6"
            mt={[12, 12, -4, -4]}
          >
            {t("mission")}
          </Text>
          {MISSION_TEXT.map((phrase, i) => (
            <Text
              key={i}
              fontSize="xl"
              lineHeight={1.8}
              mb="8"
            >
              {phrase}
            </Text>
          ))}
          {ENDING_TEXT.map((phrase, i) => (
            <Text
              key={i}
              fontSize="xl"
              lineHeight={1.8}
            >
              {phrase}
            </Text>
          ))}
          <Text
            fontWeight="bold"
            fontSize="3xl"
            color="brandBlue.500"
            mt="8"
          >
            {FINAL_TEXT}
          </Text>
        </Box>
      </Box>
    </LayoutContainer>
  );
};

export default MissionSection;
